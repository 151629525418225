<template>
  <v-switch inset v-model="groupCA" label="Grouper les CA"></v-switch>
</template>

<script>
import { URL_PARAM_NAMES } from "@/utils/constants";
import {
  addQueryStringParam,
  synchronizeFilterWithQueryString,
} from "@/utils/http";

export default {
  name: "GroupCaFilter",
  props: {
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["premium"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getGroupCA",
    },
    storeUpdater: {
      type: String,
      default: "updateGroupCA",
    },
  },
  data: () => ({
    initialized: false,
  }),
  async created() {
    await synchronizeFilterWithQueryString({
      router: this.$router,
      route: this.$route,
      store: this.$store,
      dispatcher: `${this.store}/${this.storeUpdater}`,
      param: URL_PARAM_NAMES[this.$options.name],
      value: this.groupCA,
      // is_multiple: false,
      // is_integer: false,
      is_boolean: true,
      // dependsOn: undefined,
    });

    this.initialized = true;
  },
  computed: {
    groupCA: {
      get() {
        return this.$store.getters[`${this.store}/${this.storeGetter}`];
      },
      set(e) {
        this.$store.dispatch(`${this.store}/${this.storeUpdater}`, e);
      },
    },
  },
  watch: {
    groupCA(newValue) {
      if (this.initialized) {
        // Only update user changes, not initialization
        addQueryStringParam(
          this.$router,
          this.$route,
          URL_PARAM_NAMES[this.$options.name],
          newValue
        );
      }
    },
  },
};
</script>

<style></style>
