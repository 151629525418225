<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <partner-group-filter store="premium" />
      </v-col>
      <v-col cols="6" sm="4" md="6">
        <v-row>
          <v-col cols="8">
            <partner-view-filter store="premium" />
          </v-col>
          <v-col cols="4">
            <group-ca-filter store="premium" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <date-filter
          store="premium"
          storeGetter="getMonthDate"
          storeUpdater="updateMonthDate"
          format="month"
          :isRange="false"
        />
      </v-col>
    </v-row>
    <v-row class="d-flex">
      <v-col cols="6" sm="4" md="3">
        <site-group-filter store="premium" />
      </v-col>
      <v-col cols="6" sm="4" md="6">
        <site-filter store="premium" />
      </v-col>

      <v-col cols="3">
        <v-btn
          @click="resetFilters"
          class="pt-7 pb-6 mt-0 my-1"
          outlined
          color="secondary"
        >
          Réinitialiser
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateFilter from "@/components/common/filters/DateFilter";
import GroupCaFilter from "@/components/common/filters/GroupCaFilter.vue";
import PartnerGroupFilter from "@/components/common/filters/PartnerViewGroupFilter";
import PartnerViewFilter from "@/components/common/filters/PartnerViewFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

export default {
  name: "Filters",
  components: {
    DateFilter,
    GroupCaFilter,
    PartnerGroupFilter,
    PartnerViewFilter,
    SiteFilter,
    SiteGroupFilter,
  },
  data() {
    return {};
  },
  methods: {
    resetFilters() {
      let monthDate = new Intl.DateTimeFormat("fr-FR")
        .format(new Date())
        .split("/");
      this.$store.dispatch(
        "premium/updateMonthDate",
        `${monthDate[2]}-${monthDate[1]}`
      );
      this.$store.dispatch("premium/updateSiteGroups", []);
      this.$store.dispatch("premium/updatePartnerViewGroups", []);
    },
  },
};
</script>

<style>
.v-date-picker-title__date > div {
  font-size: 22px;
}
</style>
